import * as React from "react";
import "./projects.scss";

function youtubeVideoFrameUrl(id: string) {
	return (
		"https://www.youtube.com/embed/" +
		id +
		"?autoplay=1&controls=0&loop=1&playlist=" +
		id +
		"&modestbranding=1&playsinline=1&rel=0&showinfo=0&fs=0&mute=1"
	);
}

function YoutubeVideoCard({
	id,
	ratio = 1,
	maxWidth = 400,
}: {
	id: string;
	ratio?: number;
	maxWidth?: number;
}) {
	return (
		<div
			className="card card-img video-wrap"
			style={{
				maxWidth,
			}}
		>
			<div
				className="card-youtube-video"
				style={{
					paddingBottom: Math.round(ratio * 10000) / 100 + "%",
				}}
			>
				<iframe
					src={youtubeVideoFrameUrl(id)}
					frameBorder={0}
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen={true}
				/>
			</div>
		</div>
	);
}

export function Projects() {
	return (
		<div className="page-slide page-slide-projects page-slide-padding page-slide-secondary">
			<h1 className="align-center padding-bottom-1">Projects</h1>
			<div className="project flex-parent flex-parent-image-text flex-parent-image-text-flipped">
				<YoutubeVideoCard id="hCeeW0e424c" />
				<div className="card card-text">
					<h2>Hippie Tree Friends • November 2018</h2>
					<p>
						Hippie Tree Friends is a game, some friends of mine and I developed in a
						weekend. The most complex part of this project was to coordinate 8 people to
						work on one game.
					</p>
					<p>
						Besides managing most of the software engineering I also created some of the
						art, like those adorable trees.
					</p>
					<span className="light-text">Technologies used: Unity, Photoshop, C#</span>
				</div>
			</div>
			<div className="project flex-parent flex-parent-image-text">
				<YoutubeVideoCard id="0q2RkS41Cp8" />
				<div className="card card-text">
					<h2>SmashBlox • 2017-2018</h2>
					<p>
						SmashBlox is a real-time multiplayer action game for Android. You can fight
						against your friends, random people or bots.
					</p>
					<p>
						The game has great social media integration, allowing to connect to your
						Facebook friends or Google contacts.
					</p>
					<span className="light-text">
						Technologies used: Unity, C#, NodeJS, TypeScript, RethinkDB, Facebook SDK
						...
					</span>
				</div>
			</div>
			<div className="project flex-parent flex-parent-image-text flex-parent-image-text-flipped">
				<YoutubeVideoCard id="KlbLoHdWRi4" ratio={16 / 9} maxWidth={300} />
				<div className="card card-text">
					<h2>Grack, a real-time messenger • 2015</h2>
					<p>
						Grack was a messenger which allowed for rich customization of the user
						interface, and had many features like groups, friends, sending videos and
						images.
					</p>
					<span className="light-text">
						Technologies used: NodeJS, Angular, Ionic, MySQL, ...
					</span>
				</div>
			</div>
		</div>
	);
}
