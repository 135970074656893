import * as React from "react";
import "./timeline.scss";

export interface ITimelineItemProps {
	title: string;
	children?: any;
}

export function TimelineItem(props: ITimelineItemProps) {
	return (
		<div className="card timeline-item card-gradient">
			<div className="card-inner">
				<h3>{props.title}</h3>
				{props.children}
			</div>
		</div>
	);
}

export interface ITimelineProps {
	title: string;
	children: any;
}

export function Timeline({ title, children }: ITimelineItemProps) {
	return (
		<div className="page-slide page-slide-timeline page-slide-padding">
			<h1 className="align-center">{title}</h1>
			<div className="flex-parent">
				<div className="timeline-line" />
				<div className="list">{children}</div>
			</div>
		</div>
	);
}
